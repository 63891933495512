import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";

import { annexesList } from "../../services/backend/CorrespondenceService";
import { getExtensionFile } from "../../services/backend/FileSystemService";
import { DeleteFile, UploadFile } from "../../services/backend/UploadFileService";
import { debugLog } from "../../functions";

export default function AnnexesUploader({
  referenceCode, annexes, setAnnexes, attach, setAttach, disabled, onBlur, inline = true, actions
}) {
  const user = useSelector((state) => state.user.value);
  const [annexedSelected, setAnnexedSelected] = useState({ name: '' });
  const [addingAnx, setAddingAnx] = useState(false);
  const [deletingAnx, setDeletingAnx] = useState(false);

  const handleDeleteAnnexed = async (anx) => {
    setDeletingAnx(true);

    try {
      const response = await DeleteFile(
        user,
        process.env.REACT_APP_STORAGE_BUCKET_FILED,
        `${referenceCode}/${anx.id}`
      );
      debugLog("response: ", response);
    } catch (error) {
      debugLog("response: ", error);
    }

    const index = annexes.findIndex((anx1) => {
      return anx1.id === anx.id;
    });

    if (index > -1) {
      let newAnnexes = [...annexes];
      newAnnexes.splice(index, 1);
      setAnnexes(newAnnexes);
    }
    setDeletingAnx(false);
  };

  const handleOpenAnnexed = async (anx) => {
    debugLog("anx: ", anx);
    window.open(anx.url, "_blank", "noreferrer");
  };

  const handleUploadAnnexed = async (event) => {
    let localFile = event.target.files[0];
    const extensionFile = getExtensionFile(localFile.name);
    const fileName = String(Date.now()) + extensionFile;

    if (localFile) {
      setAddingAnx(true);
      const response = await UploadFile(
        user,
        localFile,
        process.env.REACT_APP_STORAGE_BUCKET_FILED,
        `${referenceCode}/${fileName}`
      );

      let annexes1 = [...annexes];
      annexes1.push({
        id: fileName,
        name: annexedSelected.name + extensionFile,
        url: response.downloadUrl,
      });
      setAnnexes(annexes1);
      setAnnexedSelected({ name: '' });
      setAddingAnx(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ mb: 1 }}
        alignItems={"center"}
      >
        <Grid item xs={11}>
          <Autocomplete
            freeSolo
            id="annexes-autocomplete"
            size="medium"
            options={annexesList}
            disabled={addingAnx || disabled}
            value={annexedSelected}
            getOptionLabel={(option) => option.name}
            onChange={(__, newValue) => {
              setAnnexedSelected(newValue);
            }}
            onBlur={(evt) => {
              if (!annexesList.find((ann) => ann.name === evt.target.value )) {
                setAnnexedSelected({ name: evt.target.value });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Añadir Anexo"
                placeholder="Tipo Anexo"
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          {addingAnx ? (
            <CircularProgress size={24} />
          ) : (
            <label htmlFor="upload-annexed-2">
              <AttachFileIcon color={(annexedSelected?.name && "primary") || "disabled"} />
              <input
                id="upload-annexed-2"
                hidden
                accept="*"
                type="file"
                onChange={handleUploadAnnexed}
                disabled={!annexedSelected?.name}
              />
            </label>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {annexes.length > 0 ? (
          <Box
            sx={{
              p: 1,
              border: "1px dashed grey",
              "&::-webkit-scrollbar": {
                width: 3,
              },
              "&::-webkit-scrollbar-track": {
                "WebkitBoxShadow":
                  "inset 0 0 6px rgba(0,0,0,0.00)",
                //backgroundColor: "orange",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
              marginBottom: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: 'wrap'
            }}
          >
            {annexes.map((anx, idx) => (
              <Box
                key={anx.id}
                sx={{
                  border: 'thin solid green',
                  borderRadius: 10,
                  cursor: "pointer",
                  display: 'flex',
                  marginX: 1,
                  marginY: .5,
                  p: 1,
                  width: inline ? 'auto' : '100%',
                }}
                onClick={() => handleOpenAnnexed(anx)}
              >
                <Typography color="primary" sx={{ flex: 1, fontSize: '0.8em', mr: 2 }}>{anx.name}</Typography>
                {actions
                  && React.cloneElement(actions, {
                    idx,
                    annexe: anx,
                    setAnnexes
                  })
                }
                {deletingAnx ? (
                  <CircularProgress size={18} />
                ) : (
                  !disabled
                  && <IconButton onClick={(evt) => {
                    evt.stopPropagation();
                    if (!disabled) handleDeleteAnnexed(anx);
                  }} sx={{ padding: 0 }}>
                    <DeleteIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        ) : null}
      </Grid>
      {attach && setAttach
        && <Grid item xs={12} sx={{ mb: 1 }}>
          <TextField
            label="Descripción de Anexos"
            rows={3}
            fullWidth
            multiline
            defaultValue={attach}
            disabled={disabled}
            onChange={(evt) => setAttach(evt.target.value)}
            onBlur={onBlur}
          />
        </Grid>
      }
    </>
  );
}
