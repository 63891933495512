import React, { useEffect, useState, useMemo } from "react";
import {
  Modal, Box, Typography, FormControl, TextField,
  IconButton, Autocomplete, Button, CircularProgress
} from "@mui/material";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from "moment";

import { updateContract } from "../../services/backend/ContractService";
import ChargeAccountActivity from "./ChargeAccountActivity";
import MySnackbar from "../generic/MySnackbar";
import DialogConfirmation from "../generic/DialogConfirmation";

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "980px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%"
};

const ChargeAccountModal = ({
  contract, payment, open, setContract, onClose
}) => {
  const user = useSelector((state) => state.user.value);
  const [activities, setActivities] = useState([]);
  const [editing, setEditing] = useState(undefined);
  const [activity, setActivity] = useState('');
  const [clear, setClear] = useState(0);
  const [approved, setApproved] = useState();
  const [loading, setLoading] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [confirmInfo, setConfirmInfo] = useState({ open: false });

  const isContractor = useMemo(() => contract?.contractor?.userId === user.userId, [contract, user]);

  const rangeDate = useMemo(() => {
    if (!payment) return null;
    const idx = parseInt(payment.split('_')[1], 10);
    let startedAt = contract.startedAt;
    
    let finishedAt;
    if (idx === 0) {
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    } else if (idx === contract.paymentPlan.length - 1) {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = contract.finishedAt;
    } else {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    }

    return `${startedAt.format('MMM Do')} - ${finishedAt.format('MMM Do')}`;
  }, [payment, contract.startedAt, contract.finishedAt, contract.paymentPlan.length]);

  const filteredTask = useMemo(() => {
    return contract.tasks.filter((task) => !activities.map((act) => act.title).includes(task));
  }, [contract.tasks, activities]);

  const addActivity = () => {
    if (!activity) return;

    setActivities([ ...activities, { title: activity, description: '', annexes: [] }]);
    setClear(Math.floor(Math.random() * 100));
    setActivity(null);
  };

  const removeActivity = (idx) => {
    const acts = [...activities];
    acts.splice(idx, 1);
    setActivities(acts);
  };

  const updateActivity = (idx, newActivity) => {
    const acts = [...activities];
    const activity = acts[idx];
    acts[idx] = { ...activity, ...newActivity };
    setActivities(acts);
  };

  const handleCloseAccount = async () => {    
    setLoading(true);
    
    let payments = {};
    if (contract.payments) payments = {...contract.payments};
    if (!payments[payment]) payments[payment] = {};
    payments[payment].activities = activities;
    payments[payment].approved = false;

    const email = {
      emailTo: contract.supervisors[0].email,
      template: 'chargeAccountSupervisorCheck',
      data: {
        contractCode: contract.contractCode,
        usertitle: contract.supervisors[0].usertitle,
        fullname: `${contract.supervisors[0].name} ${contract.supervisors[0].lastName}`,
        position: contract.supervisors[0].position,
        entity: contract.supervisors[0].entity,
        dependence: contract.supervisors[0].dependence,
        contractorName: `${contract.contractor.name} ${contract.contractor.lastName}`,
        rangeDate
      }
    };

    await updateContract(contract.contractCode, { payments, email }, user);
    setContract({ ...contract, payments });

    setLoading(false);
    onClose();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    let message;
    activities.forEach((activity) => {
      if (!activity.description) message = 'Hay actividades sin descripción. Revisalas e ingresa una descripción';
    });

    if (message) {
      setInfoSnack({ open: true, message, severity: 'warning' });
      return;
    }

    setLoading(true);

    let payments = {};
    if (contract.payments) payments = {...contract.payments};
    if (!payments[payment]) payments[payment] = {};
    payments[payment].activities = activities;

    await updateContract(contract.contractCode, { payments }, user);
    setContract({ ...contract, payments });
    setLoading(false);
  };

  useEffect(() => {
    if (payment) {
      let pay = {};
      if (contract.payments && contract.payments[payment]) {
        pay = contract.payments[payment];
      }

      setActivities(pay.activities || []);
      setApproved(pay.approved);
    } else {
      setActivities([]);
      setApproved(undefined);
    }
  }, [payment]); // eslint-disable-line

  return (
    <>
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />

      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={(result) => {
          if (result) handleCloseAccount();
          setConfirmInfo({ open: false });
        }}
      />

      <Modal
        open={open}
        onClose={onClose}
      >
        <Box component="form" onSubmit={handleSubmit} sx={modalStyle}>
          <Typography variant="h6">Actividades y anexos</Typography>
          {approved === undefined && isContractor
            && <Box sx={{ display: "flex", my: 2, backgroundColor: "#efefef", p: 2, borderRadius: 2 }}>
              <FormControl fullWidth>
                <Autocomplete
                  key={clear}
                  fullWidth
                  options={filteredTask.filter(t => t.followup).map(t => t.title)}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    setActivity(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona una obligación y agrégala"
                      placeholder="Tipo de contrato"
                    />
                  )}
                />
              </FormControl>
              <IconButton sx={{ ml: 1, width: "56px" }} onClick={addActivity}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>
          }
          {activities.map((activity, idx) => (
            <ChargeAccountActivity
              idx={idx}
              key={idx}
              {...activity}
              contractCode={contract.contractCode}
              updateActivity={updateActivity}
              removeActivity={removeActivity}
              editing={editing === idx}
              readOnly={!isContractor || approved !== undefined}
              setEditing={setEditing}
            />
          ))}
          {(approved === undefined && isContractor)
            && <>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 5 }}
                disabled={loading}
              >
                {loading && <CircularProgress size={26} sx={{ mr: 1 }} />} Guardar actividades
              </Button>
              <Button
                variant="outlined"
                sx={{ mt: 5, ml: 3 }}
                disabled={loading}
                onClick={() => {
                  let message;
                  activities.forEach((activity) => {
                    if (!activity.description) message = 'Hay actividades sin descripción. Revisalas e ingresa una descripción';
                  });

                  if (message) {
                    setInfoSnack({ open: true, message, severity: 'warning' });
                    return;
                  }

                  setConfirmInfo({
                    open: true,
                    title: '¿Realmente deseas enviar a aprobación?'
                  })
                }}
              >
                {loading && <CircularProgress size={26} sx={{ mr: 1 }} />} Enviar para aprobación
              </Button>
            </>
          }
        </Box>
      </Modal>
    </>
  );
};

export default ChargeAccountModal;
