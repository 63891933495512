import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box, Container, FormControl, Grid, Typography, TextField, Tooltip, IconButton,
  Button, Link, CircularProgress
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import currency from "currency.js";
import moment from "moment";
import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

import { getContract, updateContract } from "../../services/backend/ContractService";
import { DeleteFile, UploadFile } from "../../services/backend/UploadFileService";

import MoneyInput from '../generic/MoneyInput';
import ChargeAccountModal from "./ChargeAccountModal";
import SocialSecurityModal from "./SocialSecurityModal";
import ChargeAccountTemplate from "./ChargeAccountTemplate";
import { ARL, PERMISSIONS, SALARIO_MINIMO, UVT } from "../../constants";
import DialogConfirmation from "../generic/DialogConfirmation";
import MyBackdrop from "../generic/MyBackdrop";

const ContractView = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const { contractId } = useParams();
  const [contract, setContract] = useState();
  const [certificates, setCertificates] = useState({ eps: null, afp: null, arl: null });
  const [showChargeAccountModal, setShowChargeAccountModal] = useState(false);
  const [showSocialSecurity, setShowSocialSecurity] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({ open: false });
  const [approvedInfo, setApprovedInfo] = useState({ open: false });
  const [openBackdrop, setOpenBackdrop] = useState();

  const isContractor = useMemo(() => contract?.contractor?.userId === user.userId, [contract, user]);
  const balance = useMemo(() => {
    if (!contract?.contractBudget) return 0;
    return contract.contractBudget - contract.paymentPlan.reduce((a, b) => a = a + b, 0);
  }, [contract?.contractBudget, contract?.paymentPlan]);

  const getRangeDate = (idx) => {
    let startedAt = contract.startedAt;
    
    let finishedAt;
    if (idx === 0) {
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    } else if (idx === contract.paymentPlan.length - 1) {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = contract.finishedAt;
    } else {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    }

    return `${startedAt.format('MMM Do')} - ${finishedAt.format('MMM Do')}`;
  };

  const handleUploadCertificate = async (evt, key) => {
    if (evt.target.files.length === 0) return;
    setLoading(true);

    const response = await UploadFile(
      user,
      evt.target.files[0],
      process.env.REACT_APP_STORAGE_BUCKET_CONTRACTS,
      `${contractId}/certificado_${key}.pdf`
    );

    const certs = { ...certificates };
    certs[key] = response.downloadUrl;
    setCertificates(certs);
    await updateContract(contractId, { certificates: certs }, user);
    setLoading(false);
  };

  const handleRemoveCertificate = async (result, data) => {
    if (!result || !data?.key) return setConfirmInfo({ open: false });
    setLoading(true);

    await DeleteFile(
      user,
      process.env.REACT_APP_STORAGE_BUCKET_CONTRACTS,
      `${contractId}/certificado_${data.key}.pdf`
    );

    const certs = { ...certificates };
    certs[data.key] = null;
    setCertificates(certs);
    await updateContract(contractId, { certificates: certs }, user);
    setLoading(false);
    setConfirmInfo({ open: false })
  };

  const handleContractApproved = async () => {
    setOpenBackdrop("Aprobando contrato");

    const email = {
      emailTo: contract.contractor.email,
      template: 'contractSupervisorApproved',
      data: {
        contractCode: contractId,
        usertitle: contract.contractor.usertitle,
        fullname: `${contract.contractor.name} ${contract.contractor.lastName}`,
        position: contract.contractor.position,
        entity: contract.contractor.entity,
        dependence: contract.contractor.dependence,
        supervisorName: `${contract.supervisors[0].name} ${contract.supervisors[0].lastName}`
      }
    };

    await updateContract(contractId, { status: 'APPROVED', email }, user);
    setContract({ ...contract, status: 'APPROVED' });
    setOpenBackdrop(undefined);
  };

  const handleChargeAccountApproved = async (result, data) => {
    if (!result || !data?.payment) return setApprovedInfo({ open: false });
    setApprovedInfo({ open: false });
    setOpenBackdrop("Aprobando cuenta de cobro");
    
    let payments = {...contract.payments};
    payments[data.payment].approved = true;
    const idx = parseInt(data.payment.split('_')[1], 10);

    const email = {
      emailTo: contract.contractor.email,
      template: 'chargeAccountSupervisorApproved',
      data: {
        contractCode: contractId,
        usertitle: contract.contractor.usertitle,
        fullname: `${contract.contractor.name} ${contract.contractor.lastName}`,
        position: contract.contractor.position,
        entity: contract.contractor.entity,
        dependence: contract.contractor.dependence,
        supervisorName: `${contract.supervisors[0].name} ${contract.supervisors[0].lastName}`,
        rangeDate: getRangeDate(idx)
      }
    };

    await updateContract(contractId, { payments, email }, user);
    setOpenBackdrop(undefined);
  };

  useEffect(() => {
    (async () => {
      if (!contract) {
        if (!user.permissions.includes(PERMISSIONS.CONTRACT.READ)) {
          return navigate("/home");
        }

        const response = await getContract(contractId, user);
        if (!response || response.status !== 'APPROVED'
          || (!user.roles.includes('ADMIN_CONTRACT')
            && response.contractor?.userId !== user.userId
            && !response.supervisorIDs?.includes(user.userId))) {
          return navigate("/home");
        }

        setContract(response);
        if (response.certificates) setCertificates(response.certificates);
      }
    })();
  }, []); // eslint-disable-line

  return !contract ? null : (
    <Container sx={{ p: 2 }}>
      <MyBackdrop open={!!openBackdrop} title={openBackdrop} />

      <ChargeAccountModal
        open={!!showChargeAccountModal}
        payment={showChargeAccountModal}
        onClose={() => setShowChargeAccountModal(false)}
        contract={contract}
        setContract={setContract}
      />
      <SocialSecurityModal
        open={!!showSocialSecurity}
        payment={showSocialSecurity}
        onClose={() => setShowSocialSecurity(false)}
        contract={contract}
        setContract={setContract}
      />
      <ChargeAccountTemplate
        contract={contract}
        payment={showTemplate}
        open={!!showTemplate}
        onClose={() => setShowTemplate(false)}
      />
      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={handleRemoveCertificate}
      />
      <DialogConfirmation
        confirmInfo={approvedInfo}
        handleCloseDialog={handleChargeAccountApproved}
      />
      <Typography variant="h4">
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          style={{ margin: 16 }}
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>
        Seguimiento a contrato: { contract.contractCode }
      </Typography>
      <Grid item xs={12} sx={{ my: 3, color: "gray" }}>
        <Typography variant="h6">{contract.contractType}</Typography>
      </Grid>
      <Box sx={{ color: "gray", my: 3 }}>
        Objeto del contrato: {contract?.contractSubject}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <TextField
              label="Código del contrato"
              defaultValue={contract.contractCode}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <TextField
              label="Nombre Contratista"
              defaultValue={contract.contractor ? `${contract.contractor.name} ${contract.contractor.lastName}` : ''}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <TextField
              label="Número de identificación"
              defaultValue={`${contract.contractor?.nuip || contract.contractorNuip}`}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <TextField
              label="Supervisor"
              defaultValue={contract.supervisors?.length > 0 ? `${contract.supervisors[0].name} ${contract.supervisors[0].lastName}` : ''}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 3 }}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <DatePicker
              label="Fecha de inicio"
              defaultValue={contract?.startedAt}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <DatePicker
              label="Fecha de terminación"
              defaultValue={contract?.finishedAt}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              label="Duración (En días)"
              defaultValue={contract?.contractPeriod}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <MoneyInput
              readOnly
              label="Valor de contrato"
              value={contract?.contractBudget}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              label="Riesgo ARL"
              defaultValue={ARL[contract?.arlLevel].text}
              InputProps={{ readOnly: true }}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} sx={{ my: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                label="Dependencia"
                defaultValue={contract?.dependency}
                InputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                label="Naturaleza del contrato"
                defaultValue={contract?.nature}
                InputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ my: 3 }}>
          <Typography variant="h6">Certificados</Typography>
        </Grid>
          {Object.keys(certificates).map((key) => (
            <Grid key={key} item xs={4}>
              {certificates[key] ? (
                <>
                  <Link
                    component="a"
                    href={certificates[key]}
                    target="_blank"
                  >
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={26} /> : `Ver Certificado ${key.toUpperCase()}`}
                    </Button>
                  </Link>
                  {isContractor
                    && <IconButton
                      color="primary"
                      onClick={() => setConfirmInfo({
                        open: true,
                        title: '¿Realmente quieres eliminar este certificado?',
                        data: { key }
                      })}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  }
                </>
              ) : (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  disabled={!isContractor}
                >
                  Cargar Certificado {key.toUpperCase()}
                  <input
                    hidden
                    accept="*"
                    type="file"
                    onChange={(evt) => handleUploadCertificate(evt, key)}
                  />
                </Button>
              )}
            </Grid>
          ))
        }
      </Grid>
      <Grid item xs={12} sx={{ mb: 5 }}>
        <Typography variant="h6">Seguimiento a Pagos</Typography>
        {balance !== 0 &&
          <Box>
            <Typography variant="h6" sx={{
              color: balance < 0 ? "#af635d" : balance > 1 ? "#a78316" : "#5e8d5e"
            }}>
              Balance: {currency(balance, { precision: 0 }).format()}
            </Typography>
            <small>Advertencia: El plan de pagos de este contrato, tiene un Balance diferente a cero</small>
          </Box>
        }
      </Grid>
      <Grid container rowSpacing={2}>
        {contract.paymentPlan?.map((pay, idx) => {
          const payment = (pay * 0.4) > SALARIO_MINIMO ? (pay * 0.4) : SALARIO_MINIMO;
          const parent_approved = idx === 0 ? true : contract.payments && contract.payments[`payment_${idx - 1}`]?.approved;
          const approved = contract.payments && contract.payments[`payment_${idx}`]?.approved;

          return (
            <React.Fragment key={idx}>
              <Grid item xs={2}>
                <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>Mes {idx + 1}: </Typography>
                <Typography sx={{ color: "gray", fontSize: "14px" }}>({getRangeDate(idx)})</Typography>
              </Grid>
              <Grid item xs={3}>
                <MoneyInput
                  label="Valor a pagar"
                  value={pay}
                  readOnly
                />
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <MoneyInput
                  label="Pago Seguridad Social"
                  value={payment * (0.12 + 0.165 + parseFloat(ARL[contract.arlLevel].value))}
                  disabled
                />
                <Tooltip
                    title={(
                      <Grid container sx={{ p: 1, fontSize: 16 }}>
                        <Grid item xs={6}>Salud</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * 0.125, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>Pensión</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * 0.16, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>ARL</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * parseFloat(ARL[contract.arlLevel].value), { precision: 0 }).format()}
                        </Grid>
                      </Grid>
                    )}
                  >
                    <IconButton sx={{ marginLeft: -4 }}>
                      <InfoIcon sx={{ color: "gray", fontSize: 20 }}/>
                    </IconButton>
                  </Tooltip>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <MoneyInput
                  label="Estampillas"
                  value={pay * (0.004 + (pay > 145 * UVT ? 0.01 : 0) + 0.02 + 0.006 + 0.004 + (pay > 300 * UVT ? 0.004 : 0))}
                  disabled
                />
                <Tooltip
                  title={(
                    <Grid container sx={{ p: 1, fontSize: 16 }}>
                      <Grid item xs={6}>D59 (IU Env)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {currency(pay * 0.004, { precision: 0 }).format()}
                      </Grid>
                      <Grid item xs={6}>D63 (Pro Hosp)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {pay > 145 * UVT ? currency(pay * 0.01, { precision: 0 }).format() : 0}
                      </Grid>
                      <Grid item xs={6}>D87 (Pro Bien)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {currency(pay * 0.02, { precision: 0 }).format()}
                      </Grid>
                      <Grid item xs={6}>D88 (Pro Dllo)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {currency(pay * 0.006, { precision: 0 }).format()}
                      </Grid>
                      <Grid item xs={6}>D89 (Politécnico)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {currency(pay * 0.004, { precision: 0 }).format()}
                      </Grid>
                      <Grid item xs={6}>D124 (IU Digital)</Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        {pay > 300 * UVT ? currency(pay * 0.004, { precision: 0 }).format() : 0}
                      </Grid>
                    </Grid>
                  )}
                >
                  <IconButton sx={{ marginLeft: -4 }}>
                    <InfoIcon sx={{ color: "gray", fontSize: 20 }}/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                {parent_approved && contract.status === 'APPROVED'
                  && <>
                    <Tooltip title="Registro de seguridad social">
                      <IconButton size="small" onClick={() => setShowSocialSecurity(`payment_${idx}`)}>
                        <HealthAndSafetyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Registro de actividades y anexos">
                      <IconButton size="small" onClick={() => setShowChargeAccountModal(`payment_${idx}`)}>
                        <ListAltIcon color={approved === undefined ? "action"
                          : approved === false ? "warning": "success"} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Previsualizar Cuenta de cobro">
                      <IconButton size="small" onClick={() => setShowTemplate(`payment_${idx}`)}>
                        <DescriptionIcon color={approved ? "success": "action"} />
                      </IconButton>
                    </Tooltip>
                    {!approved && user.permissions.includes(PERMISSIONS.SUPERVISOR_CONTRACT.APPROVE)
                      && <Tooltip title="Aprobar Cuenta de cobro">
                        <IconButton size="small" onClick={() => setApprovedInfo({
                          open: true,
                          title: "Desea aprobar esta cuenta de cobro?",
                          data: { payment: `payment_${idx}` }
                        })}>
                          <TaskAltIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </>
                }
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        {contract.status === 'SUPERVISOR'
          && <>
            <Button
              variant="contained"
              onClick={handleContractApproved}
            >
              Aprobar supervisión
            </Button>
          </>
        }
      </Grid>
    </Container>
  );
};

export default ContractView;
