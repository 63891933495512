import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TableCell, TableRow, IconButton, Box,
  Modal,
  Typography
} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DescriptionIcon from '@mui/icons-material/Description';

import { PERMISSIONS } from "../../constants";

const style = {
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const statusColor = {
  DRAFT: 'gray',
  SUPERVISOR: 'orange',
  APPROVED: 'green'
}

const ContractItem = ({ contract }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">Objeto del contrato</Typography>
          <Typography sx={{ mt: 2 }}>
            {contract.contractSubject}
          </Typography>
        </Box>
      </Modal>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: statusColor[contract.status],
                mr: 1,
                borderRadius: '50%'
              }}
            />
            {contract.contractCode}
          </Box>
        </TableCell>
        <TableCell>
          {contract.supervisors?.length ? (
            contract.supervisors.map(s => (<div key={s.userId}>{s.name} {s.lastName}</div>))
          ) : (
            <Typography color='orange'>Sin supervisor</Typography>
          )}
        </TableCell>
        <TableCell>
          {contract.contractor ? (
            `${contract.contractor.name} ${contract.contractor.lastName}`
          ) : (
            <Typography color='#d19435'>Sin contratista</Typography>
          )}
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setOpen(true)}>
              <DescriptionIcon fontSize="small" color="info" />
            </IconButton>
            <Box sx={{ marginLeft: '0.5em'}}>{contract.contractSubject.substr(0, 100)}</Box>
          </Box>
        </TableCell>
        <TableCell>
        {contract.contractPeriod}
        </TableCell>
        <TableCell>
          <Box style={{ flexDirection: 'row', alignItems: 'center', minWidth: 120 }}>
            <IconButton onClick={() => window.open(contract.fileUrl, '_blank')}>
              <OpenInNewIcon size={20} />
            </IconButton>
            {(user.permissions.includes(PERMISSIONS.CONTRACT.UPDATE)
              || user.permissions.includes(PERMISSIONS.SUPERVISOR_CONTRACT.UPDATE))
              && <IconButton onClick={() => navigate(`/contracts/${contract.contractCode}`)}>
                <ModeEditIcon size={20} />
              </IconButton>
            }
            {contract.status === 'APPROVED' && user.permissions.includes(PERMISSIONS.CONTRACT.READ)
              && <IconButton onClick={() => navigate(`/contracts/${contract.contractCode}/view`)}>
                <DescriptionIcon size={20} />
              </IconButton>
            }
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContractItem;
