import { Box, Button, Chip, IconButton } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";

const UploadButton = ({ label, onChange, maxWidth = 300 }) => {
  const [name, setName] = useState('');

  const handleChange = (evt) => {
    if (evt.target.files.length === 0) {
      setName('');
      return;
    }

    if (onChange) onChange(evt.target.files);
    setName(evt.target.files[0].name);
  };

  return (
    <Box>
      <Button
        role={undefined}
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        sx={{ maxWidth, mr: 2, mb: 1 }}
      >
        {label}
        <input
          hidden
          accept=".pdf"
          type="file"
          onChange={handleChange}
        />
      </Button>
        {name
          && <Box>
            <Chip label={name} sx={{ maxWidth: '80%' }} />
            <IconButton onClick={() => {
              setName('');
              onChange(undefined);
            }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        }
    </Box>
  );
};

export default UploadButton;
