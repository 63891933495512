import { useState } from "react";
import { Box, Select, Typography, Grid, Button, Autocomplete } from "@mui/material";

import { getControl } from "../../fields";
import { DatePicker } from "@mui/x-date-pickers";
import MoneyInput from "../generic/MoneyInput";
import MySnackbar from "../generic/MySnackbar";
import AnnexesUploader from "../generic/AnnexesUploader";

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "980px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%"
};

const controls = {
  type: {
    type: Select,
    options: [
      "Adición económica al contrato",
      "Cambios de responsabilidades al contrato",
      "Suspensión de contrato",
      "Terminación anticipada de contratos"
    ],
    label: "Tipo de novedad",
    xs: 12
  },
  startedAt: {
    type: DatePicker,
    label: "Fecha de inicio de novedad",
    xs: 4
  },
  endedAt: {
    type: DatePicker,
    label: "Fecha de finalización de novedad",
    xs: 4,
    onValidation: (submission) => submission.type !== "Terminación anticipada de contratos"

  },
  amount: {
    type: MoneyInput,
    label: "Adición al contrato",
    xs: 4,
    onValidation: (submission) => submission.type === "Adición económica al contrato"
  },
  description: {
    type: Autocomplete,
    freeSolo: true,
    options: [
      "Viaje",
      "Incapacidad médica",
      "Calamidad doméstica",
      "Licencia de maternidad",
      "Licencia de paternidad",
      "Solicitud del contratista"
    ],
    label: "Description",
    placeholder: "Incapacidad médica",
    xs: 12
  }
};

const ContractNewsForm = ({ contract }) => {
  const [submission, setSubmission] = useState(
    Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
  );
  const [infoSnack, setInfoSnack] = useState({ open: false });

  const handleSubmit = () => {
    let message;
    if (submission.startedAt.isBefore(contract.startedAt)) {
      message = "La fecha de inicio de la novedad no puede ser inferior a la fecha de inicio del contrato";
    }
    
    if (submission.endedAt.isAfter(contract.finishedAt)) {
      message = "La fecha de finalización de la novedad no puede ser superior a la fecha de finalización del contrato";
    }
    
    if (submission.type === "Adición económica al contrato" && !submission.amount) {
      message = "Especifique una valor de adición al contrato";
    }

    if (message) return setInfoSnack({ open: true, message, severity: "error" });
  };

  const handleChange = (key, value) => {
    setSubmission({ ...submission, [key]: value });
  };

  return (
    <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ color: 'gray' }}>
          <Typography variant="h6">Añadir novedad</Typography>
        </Grid>
        {Object.keys(controls).map((key) => getControl({
          ...controls[key],
          key,
          handleChange,
          value: submission[key],
          submission
        }))}
        <Grid item xs={12}>
          <AnnexesUploader annexes={[]} />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
          <Button
            variant="contained"
            type="button"
            onClick={handleSubmit}
          >
            Añadir novedad
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractNewsForm;
