import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Select
} from "@mui/material";

import { getControl } from "../../fields";
import UploadButton from "../generic/UploadButton";

const controls = {
  description: {
    type: Select,
    options: ['2025-01', '2025-02'],
    label: "Semestre a postularse",
    xs: 12
  },
  judicial: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Antecedentes Judiciales - Policia Nacional",
    xs: 12
  },
  disciplinary: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Antecedentes Disciplinarios - Procuraduría",
    xs: 12
  },
  fiscal: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Antecedentes Fiscales - Contraloría",
    xs: 12
  },
  corrective: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Antecedentes Fiscales - Contraloría",
    xs: 12
  },
  food: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Deudores Alimentarios Moroso - REDAM",
    xs: 12
  },
  sexual: {
    type: UploadButton,
    required: true,
    maxWidth: 'auto',
    label: "Inhabilidades por Delitos Sexuales - Policia Nacional",
    xs: 12
  }
};

const SubmissionForm = () => {
  const [submission, setSubmission] = useState(
    Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
  );

  const handleSubmit = () => {
  };

  const handleChange = (key, value) => {
    setSubmission({ ...submission, [key]: value });
  };

  return (
    <Container>
      <Grid container className="">
        <Grid item xs={12}>
          <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ color: 'gray' }}>
                <Typography variant="h6">Certificados de Antecedentes</Typography>
                <Box>
                  Por favor adjunte cada uno de los siguientes documentos requeridos para el
                  proceso de contratación de docentes de cátedra.
                </Box>
              </Grid>
              {Object.keys(controls).map((key) => getControl({
                ...controls[key],
                key,
                handleChange,
                value: submission[key]
              }))}
              <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                >Siguiente</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
};

export default SubmissionForm;
