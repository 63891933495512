import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  addUser,
  addPeople,
  updateUser,
  updatePeople,
} from "../../services/backend/UserService";
import {
  setUser,
  addPeople as addPeopleToStore,
  addUser as addUserToStore,
  updatePeople as updatePeopleToStore,
  updateUsers as updateUserToStore,
} from "../../redux/slices/userSlice";
import UploadFile from "./UploadFile";
import ProgressUploadFile from "./ProgressUploadFile";
import UserPasswordModal from "./UserPasswordModal";
import { debugLog } from "../../functions";

const titles =  [
  "Dr.", "Señor", "Señora", "Señores", "Estudiante", "Estudiantes", "Profesor",
  "Profesores", "Doctor", "Doctora", "Doctores"
];

const UserProfile = ({
  userType,
  showPhoto,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [usertitle, setUsertitle] = useState("Señor");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postal, setPostal] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [nuip, setNuip] = useState("");
  const [entity, setEntity] = useState("");
  const [dependence, setDependence] = useState("");
  const [level, setLevel] = useState("");
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false);

  const handleFileUpload = (files) => {
    debugLog("files: ", files);
    setFile(files[0]);
  };

  const onUploadFileUrl = async (url, file) => {
    debugLog("url: ", url);
    try {
      const userData = { userId: user.userId, avatarUrl: url };
      await updateUser(userData, user);
      dispatch(setUser({ ...user, ...userData }));
    } catch (error) {
      debugLog("error: ", error);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setLoading(true);

    const userData = {
      usertitle,
      name,
      lastName,
      city,
      country,
      postal,
      phone,
      address,
      email,
      nuip,
      entity,
      dependence,
      level,
      position
    };

    if (userType === "users") {
      userData.userId = user?.userId;
      if (user) {
        await updateUser(userData, user);
        dispatch(updateUserToStore(userData));
      } else {
        await addUser(userData, user);
        dispatch(addUserToStore(userData));
      }
    } else if (userType === "people") {
      userData.peopleId = user?.peopleId;
      if (user) {
        await updatePeople(userData, user);
        dispatch(updatePeopleToStore(userData));
      } else {
        await addPeople(userData, user);
        dispatch(addPeopleToStore(userData));
      }
    }

    if (onSubmit) onSubmit(userData);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setUsertitle(user.usertitle || "Señor");
      setName(user.name || "");
      setLastName(user.lastName || "");
      setCity(user.city || "");
      setCountry(user.country || "");
      setPostal(user.postal || "");
      setPhone(user.phone || "");
      setAddress(user.address || "");
      setEmail(user.email || "");
      setNuip(user.nuip || "");
      setEntity(user.entity || "");
      setDependence(user.dependence || "");
      setLevel(user.level || "");
      setPosition(user.position || "");
    }
  }, [user]);

  return (
    <Container maxWidth="md">
      <UserPasswordModal
        open={showUserPasswordModal}
        onClose={() => setShowUserPasswordModal(false)}
      />
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
        <Grid container spacing={1}>
          {showPhoto ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <UploadFile
                      accept={"image/*"}
                      handleFileUpload={handleFileUpload}
                    />
                  }
                >
                  <Avatar
                    alt="Travis Howard"
                    src={user.avatarUrl}
                    sx={{ width: 100, height: 100 }}
                  />
                </Badge>
                <ProgressUploadFile
                  file={file}
                  onUploadFileUrl={onUploadFileUrl}
                />
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={2}>
            <FormControl fullWidth>
              <Select                
                value={usertitle}
                onChange={(evt) => setUsertitle(evt.target.value)}
              >
                {titles.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={5}>
            <FormControl fullWidth>
              <TextField
                label="Nombres"
                required
                value={name}
                disabled
                onChange={(evt) => setName(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <TextField
                label="Apellidos"
                required
                value={lastName}
                disabled
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Correo electrónico"
                required
                value={email}
                disabled
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Número de identificación"
                required
                value={nuip}
                onChange={(evt) => setNuip(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Dirección"
                required
                value={address}
                onChange={(evt) => setAddress(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Celular"
                required
                value={phone}
                onChange={(evt) => setPhone(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                label="País"
                value={country}
                onChange={(evt) => setCountry(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                label="Ciudad"
                required
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                label="Código postal"
                value={postal}
                onChange={(evt) => setPostal(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Entidad"
                value={entity}
                onChange={(evt) => setEntity(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Dependencia"
                value={dependence}
                onChange={(evt) => setDependence(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Nivel"
                value={level}
                onChange={(evt) => setLevel(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Cargo"
                value={position}
                onChange={(evt) => setPosition(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Guardar"}
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => setShowUserPasswordModal(true)}
            >
              Cambiar contraseña
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserProfile;
