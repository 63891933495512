import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { getControl } from "../../fields";
import UploadButton from "../generic/UploadButton";


const controls = {
  degree: {
    type: TextField,
    required: true,
    label: "Título",
    placeholder: "Ingeniero de Sistemas",
    xs: 12
  },
  level: {
    type: Select,
    options: ['Secundaria', 'Técnico', 'Tecnólogo', 'Profesional', 'Especialización', 'Maestría', 'Doctorado'],
    label: "Nivel académico",
    xs: 12
  },
  mode: {
    type: Select,
    options: ['Presencial', 'A distancia', 'Virtual'],
    required: true,
    label: "Modalidad",
    xs: 12
  },
  institution: {
    type: TextField,
    required: true,
    label: "Institución educativa",
    xs: 12
  },
  startedAt: {
    type: DatePicker,
    required: true,
    label: "Fecha de inicio",
    xs: 6
  },
  endedAt: {
    type: DatePicker,
    required: true,
    label: "Fecha de finalización",
    xs: 6
  },
  certificate: {
    type: UploadButton,
    required: true,
    label: "Acta de grado / Diploma",
    xs: 12
  },

};

const EducationForm = () => {
  const { nuip } = useParams();
  const [educations, setEducations] = useState([
    Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
  ]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleAddEducation = () => {
    setEducations([
      ...educations,
      Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
    ]);
  };

  const handleChange = (key, value, idx) => {
    const submss = { ...educations[idx], [key]: value };
    const edus = [...educations];
    edus[idx] = submss;
    setEducations(edus);
  };

  return (
    <Container maxWidth={false}>
      <Grid container className="">
        <Grid item xs={12}>
          <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={11} sx={{ color: 'gray' }}>
                <Typography variant="h6">Educación</Typography>
              </Grid>
            </Grid>
            {educations.map((education, idx) => (
              <Grid
                key={idx}
                container
                spacing={2}
                sx={{ backgroundColor: '#f7f7f7', my: 2, borderRadius: 2, p: 2 }}
              >
                {Object.keys(controls).map((key) => getControl({
                  ...controls[key],
                  key,
                  idx,
                  handleChange,
                  value: education[key]
                }))}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={education.checked || false}
                        size="small"
                        onChange={(evt) => handleChange(idx, 'checked', evt.target.checked)}
                      />
                    }
                    label="Título obtenido en el exterior"
                  />
                </Grid>
                {education.checked && getControl({
                  type: UploadButton,
                  required: true,
                  label: "Resolución de homologación",
                  xs: 12,
                  key: 'homologation',
                  idx,
                  handleChange
                })}                
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="text" onClick={handleAddEducation}>+ Añadir Educación</Button>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                >Siguiente</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
};

export default EducationForm;
