import * as React from "react";
import Header from "./Header";
import ContractForm from "../contract/ContractForm";
import ContractList from "../contract/ContractList";
import ContractView from "../contract/ContractView";

export default function LayoutContracts({ list, view, userType }) {
  return (
    <>
      <Header />
      {list ? <ContractList userType={userType} />
      : view ? <ContractView /> : <ContractForm />}
    </>
  );
}
