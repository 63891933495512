import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const DialogConfirmation = ({ confirmInfo, withFeedback, handleCloseDialog }) => {
  const [message, setMessage] = useState('');

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const handleDeleteRequestType = () => {
    handleCloseDialog(true, { ...confirmInfo.data, message });
    setMessage('');
  };

  return (
    <Dialog
      open={confirmInfo.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{confirmInfo.title}</DialogTitle>
      {withFeedback
        && <DialogContent>
          <TextField
            fullWidth
            multiline
            size="small"
            rows={3}
            placeholder={withFeedback}
            onChange={(evt) => setMessage(evt.target.value)}
          />
        </DialogContent>}
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {confirmInfo.cancel ? confirmInfo.cancel : "Cancelar"}
        </Button>
        <Button onClick={handleDeleteRequestType} color="primary">
          {confirmInfo.confirm ? confirmInfo.confirm : "Confirmar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
