import { Box, Container, Typography, Button } from "@mui/material";

const Submission = () => (
  <Container>
    <Box sx={{
      backgroundColor: "#f7f7f7",
      p: 3,
      margin: '10px auto',
      borderRadius: 2,
      maxWidth: 520
    }}>
      <Typography variant="h4">
        Registro de Docentes de Cátedra y Postulaciones
      </Typography>
      <Box sx={{ my: 3}}>
        Esta herramienta está diseñada para optimizar y gestionar el proceso de contratación
        semestral de los docuentes de cátedra. Esta plataforma permite a los docentes
        postularse a las diferentes cátedras ofrecidas por la institución, mientras facilita
        a los departamentos académicos y administrativos llevar un control eficiente de las
        postulaciones, selección y contratación.
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
        <Button href="/login" variant="contained">Iniciar sesión</Button>
        <Button href="/submission/new" variant="contained">Postúlate</Button>
      </Box>
    </Box>
  </Container>
);

export default Submission;
