import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { getControl } from "../../fields";

const controls = {
  position: {
    type: TextField,
    required: true,
    label: "Cargo",
    placeholder: "Ejemplo: Profesor de Ingeniería",
    xs: 12
  },
  job_type: {
    type: Select,
    options: ['Jornada Completa', 'Jornada parcial', 'Independiente','Contrato temporal', 'Prestación de servicios'],
    label: "Tipo de Empleo",
    xs: 12
  },
  company: {
    type: TextField,
    required: true,
    label: "Nombre de la empresa",
    placeholder: "Ejemplo: Técnológico de Antioquia",
    xs: 12
  },
  location: {
    type: TextField,
    required: true,
    label: "Ubicación",
    xs: 12
  },
  startedAt: {
    type: DatePicker,
    required: true,
    label: "Fecha de inicio",
    xs: 6
  },
  endedAt: {
    type: DatePicker,
    required: true,
    label: "Fecha de finalización",
    xs: 6
  },
  description: {
    type: TextField,
    required: true,
    multiline: true,
    rows: 3,
    label: "Descripción",
    xs: 12
  }
};

const JobForm = () => {
  const { nuip } = useParams();
  const [jobs, setJobs] = useState([
    Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
  ]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleAddJob = () => {
    setJobs([
      ...jobs,
      Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
    ]);
  };

  const handleChange = (key, value, idx) => {
    console.log(idx, key, value)
    const submss = { ...jobs[idx], [key]: value };
    const jbs = [...jobs];
    jbs[idx] = submss;
    setJobs(jbs);
  };

  return (
    <Container maxWidth={false}>
      <Grid container className="">
        <Grid item xs={12}>
          <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={11} sx={{ color: 'gray' }}>
                <Typography variant="h6">Experiencia laboral</Typography>
              </Grid>
            </Grid>
            {jobs.map((job, idx) => (
              <Grid
                key={idx}
                container
                spacing={2}
                sx={{ backgroundColor: '#f7f7f7', my: 2, borderRadius: 2, p: 2 }}
              >
                {Object.keys(controls).map((key) => getControl({
                  ...controls[key],
                  key,
                  idx,
                  handleChange,
                  value: job[key]
                }))}
              </Grid>
            ))}
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="text" onClick={handleAddJob}>+ Añadir Puesto de Trabajo</Button>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
};

export default JobForm;
