import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getControl } from "../../fields";

const habeasData = `Autorización para el tratamiento de datos personales.
Al utilizar esta aplicación usted consiente expresamente el tratamiento de sus datos
personales por parte de la Intitución Universitaria Tecnológico de Antioquia,
en cumplimiento de la Ley 1551 de 2012. Sus datos serán utilizados para fines
académicos y administrativos. Tiene derecho a conocer, actualizar, rectificar y
solicitar la eliminación de su información. Al continuar acepta los términos
establecidos`;

const controls = {
  firstname: {
    type: TextField,
    required: true,
    label: "Nombre",
    xs: 6
  },
  lastname: {
    type: TextField,
    required: true,
    label: "Apellidos",
    xs: 6
  },
  phone: {
    type: TextField,
    required: true,
    label: "Celular",
    xs: 6
  },
  birthday: {
    type: DatePicker,
    required: true,
    label: "Fecha de nacimiento",
    xs: 6
  },
  email: {
    type: TextField,
    required: true,
    label: "Email",
    xs: 12
  },
  documentType: {
    type: Select,
    options: ['Cédula de ciudadanía', 'Cédula de extranjería', 'Pasaporte'],
    required: true,
    label: "Tipo de documento",
    xs: 6
  },
  documentId: {
    type: TextField,
    required: true,
    label: "Documento de identidad",
    xs: 6
  },
  documentAt: {
    type: DatePicker,
    required: true,
    label: "Fecha de expedición",
    xs: 6
  },
  nacionality: {
    type: TextField,
    required: true,
    label: "Nacionalidad",
    xs: 6
  },
  country: {
    type: TextField,
    required: true,
    label: "País",
    xs: 6
  },
  state: {
    type: TextField,
    required: true,
    label: "Departamento",
    xs: 6
  },
  city: {
    type: TextField,
    required: true,
    label: "Ciudad",
    xs: 6
  },
  address: {
    type: TextField,
    required: true,
    label: "Dirección",
    xs: 6
  }
};

const SubmissionForm = () => {
  const [checked, setChecked] = useState(false);
  const [submission, setSubmission] = useState(
    Object.fromEntries(Object.entries(controls).map(([key]) => [key, ""]))
  );

  const handleSubmit = () => {
  };

  const handleChange = (key, value) => {
    setSubmission({ ...submission, [key]: value });
  };

  return (
    <Container maxWidth={false}>
      <Grid container className="">
        <Grid item xs={12}>
          <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ color: 'gray' }}>
                <Typography variant="h6">Datos personales</Typography>
              </Grid>
              {Object.keys(controls).map((key) => getControl({
                ...controls[key],
                key,
                handleChange,
                value: submission[key]
              }))}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      size="small"
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                  }
                  label={<Typography sx={{ fontSize: 10 }}>{habeasData}</Typography>}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!checked}
                >Siguiente</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
};

export default SubmissionForm;
