import React, { useMemo } from "react";
import {
  AppBar, Dialog, IconButton, Toolbar, Typography, Slide
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RichEditor from "../generic/rich-editor";
import moment from 'moment';
import currency from "currency.js";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChargeAccountTemplate = ({
  contract, payment, open, onClose
}) => {
  const users = useSelector((state) => state.user.users);

  const accountDate = useMemo(() => {
    if (!payment) return;
    const idx = parseInt(payment.split('_')[1], 10);
    let startedAt = contract.startedAt;
    
    let finishedAt;
    if (idx === 0) {
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    } else if (idx === contract.paymentPlan.length - 1) {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = contract.finishedAt;
    } else {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    }

    return finishedAt;
  }, [payment]); // eslint-disable-line

  const activities = useMemo(() => {
    return contract.payments && contract.payments[payment]?.activities.map(
      ac => `(${contract?.tasks.map(t => t.title).indexOf(ac.title)+ 1}) ${ac.description}`
    ).sort((a, b) => a > b ? 1: a < b ? -1 : 0).join(', ')
  }, [contract.payments, payment, contract.tasks]);

  const socialSecurity = useMemo(() => {
    if (!payment) return null;

    const idx =  parseInt(payment.split('_')[1], 10);
    if (idx === 0) return { payroll: 'N/A', contribution: 'N/A', payAt: 'N/A', payrollCertificate: 'NO', pensioner: 'N/A' };
    const lastPayment = contract.payments[`payment_${idx - 1}`];

    return {
      payroll: lastPayment.payroll,
      contribution: lastPayment.contribution && currency(lastPayment.contribution, { precision: 0 }).format(),
      payAt: lastPayment.payAt.format('DD/MM/YYYY'),
      payrollCertificate: (lastPayment.certificates?.payroll || lastPayment.certificates?.form || lastPayment.certificates?.trasaction) ? 'SI' : 'NO',
      pensioner: lastPayment.certificates?.pensioner ? 'SI': 'NO'
    }
  }, [contract.payments, payment]);

  const contractor = useMemo(() => {
    if (!users) return {};
    const user = users.find(u => u.userId === contract.contractor.userId);

    return {
      contractorName: `${user.name} ${user.lastName}`,
      contractorNuip: user.nuip || '-',
      contractorAddress: user.address || '-',
      contractorPhone: user.phone || '-',
    }
  }, [users, contract.contractor]);

  const supervisor = useMemo(() => {
    if (!users) return {}
    const user = users.find(u => u.userId === contract.supervisors[0].userId);
    return {
      supervisorName: `${user.name} ${user.lastName}`,
      supervisorDependence: user.dependence,
      supervisorPosition: user.position,
      supervisorNuip: user.nuip || '-',
    };
  }, [users, contract.supervisors]);

  const getRangeDate = (idx) => {
    let startedAt = contract.startedAt;
    
    let finishedAt;
    if (idx === 0) {
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    } else if (idx === contract.paymentPlan.length - 1) {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = contract.finishedAt;
    } else {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    }

    return `${startedAt.format('MMMM D')} al ${finishedAt.format('D')}`;
  };

  const handleDocumentLoaded = (editor) => {
    let table0 = editor.document.tables.getByIndex(0)?._table;
    if (!table0) return;

    const payments = [];
    for (let i = 0; i <= parseInt(payment.split('_')[1], 10); i++) {
      const pay = contract.paymentPlan[i];
      const accrued = payments.map((p) => p.pay).reduce((a, v) => a + v, 0) + pay;
      const porcentage = (pay / contract.contractBudget) * 100;

      payments.push({
        pay,
        porcentage,
        accrued: accrued,
        pending: contract.contractBudget - accrued,
        accrued_porcentage: payments.map((p) => p.porcentage).reduce((a, v) => a + v, 0) + porcentage
      });

      setTimeout(() => {
        editor.mailMergeOptions.viewMergedData = true;
        editor.readOnly = true;
      }, 500);
    }

    const initRow = 25;
    payments.forEach((payment, idx) => {
      const cells = table0.rows[initRow + idx].cells;
      editor.document.insertText(cells[1].interval.start, currency(contract.contractBudget, { precision: 0 }).format());
      editor.document.insertText(cells[2].interval.start, currency(payment.pay, { precision: 0 }).format());
      editor.document.insertText(cells[3].interval.start, `${payment.porcentage.toFixed(2)}%`);
      editor.document.insertText(cells[4].interval.start, currency(payment.accrued, { precision: 0 }).format());
      editor.document.insertText(cells[5].interval.start, currency(payment.pending, { precision: 0 }).format());
      editor.document.insertText(cells[6].interval.start, `${payment.accrued_porcentage.toFixed(2)}%`);
    });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Cuenta de cobro
          </Typography>
        </Toolbar>
      </AppBar>
      <RichEditor
        noRibbon
        file={{
          name: 'L2ZtQv6TSbBcBNM2',
          path: 'templates/L2ZtQv6TSbBcBNM2',
          url: 'https://firebasestorage.googleapis.com/v0/b/royalplusdev.appspot.com/o/documents%2Ftemplates%2FL2ZtQv6TSbBcBNM2?alt=media&token=948d649f-c536-4294-9c11-24b3c24ce95a'
        }}
        onDocumentLoaded={handleDocumentLoaded}
        dataSource={{
          ...contract,
          ...contractor,
          dependency: contract.dependency,
          startedAt: contract.startedAt.format('DD/MM/YYYY'),
          finishedAt: contract.finishedAt.format('DD/MM/YYYY'),
          accountPeriod: payment && `${getRangeDate(parseInt(payment.split('_')[1], 10))} de ${accountDate?.format('YYYY')}`,
          number: payment && parseInt(payment.split('_')[1], 10) + 1,
          date_day: accountDate?.format('DD'),
          date_month: accountDate?.format('MM'),
          date_year: accountDate?.format('YYYY'),
          payment: contract.payments && contract.payments[payment],
          tasks: contract.payments && contract.tasks.map((t, idx) => `${idx + 1}. ${t.title}`).join(', '),
          activities,
          ...socialSecurity,
          ...(contract.payments && contract.payments[payment]?.risk ? { risk: 'X' } : { noRisk: 'X' }),
          ...supervisor
        }}
      />
    </Dialog>
  );
};

export default ChargeAccountTemplate;
