import * as React from "react";
import Header from "./Header";
import Submission from "../submissions/Submission";
import SubmissionForm from "../submissions/SubmissionForm";
import EducationForm from "../submissions/EducationForm";
import JobForm from "../submissions/JobForm";
import CertificateForm from "../submissions/CertificateForm";

export default function LayoutSubmission({ type }) {
  return (
    <>
      <Header />
      {type === "home" && <Submission />}
      {type === "profile" && <SubmissionForm />}
      {type === "education" && <EducationForm />}
      {type === "job" && <JobForm />}
      {type === "certificate" && <CertificateForm />}
    </>
  );
}
