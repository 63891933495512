import * as React from "react";
import Header from "./Header";
import UserProfile from "../generic/UserProfile";

export default function LayoutUserProfile() {
  return (
    <>
      <Header />
      <UserProfile userType="users" showPhoto={true} />
    </>
  );
}
