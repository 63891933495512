import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete, Avatar, Box, Button, Dialog, FormControl, Grid, InputLabel,
  MenuItem, Select, TextField, Typography, CircularProgress,
  Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { addExpedient, updateExpedient } from "../../services/backend/ExpedientService";
import SelectUser from "../generic/SelectUser";

const defaultFields = {
  expedientId: '',
  expedientTypeId: '',
  description: '',
  metadata: '',
  status: 'opened',
  keywords: [],
  viewers: [],
  editors: []
};

const ExpedientFormModal = ({
  open, expedient, expedientTypeId, trd, isUpdate, onClose, onSubmit
}) => {
  const user = useSelector((state) => state.user.value);
  const users = useSelector((state) => state.user.users);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({ ...defaultFields });
  const [viewers, setViewers] = useState([]);
  const [editors, setEditors] = useState([]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setLoading(true);
    const trdData = {
      trd,
      dependence: trd.dependence.code,
      serie: trd.serie.code,
      subserie: trd.subserie.code
    };

    let response;

    if (isUpdate) {
      response = await updateExpedient({ ...fields, ...trdData }, user);
    } else {
      response = await addExpedient({ ...fields, ...trdData }, user);
    }

    if (response && onSubmit) onSubmit(response);
    setLoading(false);
    setFields(defaultFields);
    onClose();
  };

  useEffect(() => {
    if (expedient && users) {
      setFields({ ...fields, ...expedient});
      setViewers(expedient.viewers?.map((userId) => users.find((usr) => usr.userId === userId)));
      setEditors(expedient.editors?.map((userId) => users.find((usr) => usr.userId === userId)));
    }
  }, [expedient, open, users]); // eslint-disable-line

  useEffect(() => {
    if (open && !expedient?.expedientTypeId) setFields({ ...fields, expedientTypeId });
  }, [expedientTypeId, open]); // eslint-disable-line

  useEffect(() => {
    if (!open) setFields(defaultFields);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {expedient ? "Modificar" : "Crear"} expediente
            </Typography>
          </Grid>
          <Grid item xs={2} alignItems="center" sx={{ display: "flex" }}>
            <Button>
              <Avatar>R</Avatar>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Tipo de expediente"
                value={fields.expedientTypeId}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Identificador del expediente"
                name="expedientId"
                value={fields.expedientId}
                onChange={(evt) => setFields({ ...fields, expedientId: evt.target.value })}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Descripción"
                name="description"
                value={fields.description}
                onChange={(evt) => setFields({ ...fields, description: evt.target.value })}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="expedient-status">Estado</InputLabel>
              <Select
                labelId='expedient-status'
                label="Estado"
                value={fields.status}
                onChange={(evt) => setFields({ ...fields, status: evt.target.value })}
              >
                <MenuItem value="opened">Abierto</MenuItem>
                <MenuItem value="closed">Cerrado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Metadatos y palabras claves
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ marginY: 1 }}>
                      <TextField
                        multiline
                        rows={5}
                        label="Metadatos"
                        name="metadata"
                        defaultValue={fields.metadata && Object.keys(fields.metadata).map(
                          (key) => `${key}: ${fields.metadata[key]}`
                        ).join('\n')}
                        onChange={(evt) => {
                          const lines =  evt.target.value.split('\n').map((data) => {
                            const d = {};
                            d[data.split(':')[0]] = data.split(':')[1];
                            return d;
                          });
                          const metadata = {};
                          lines.forEach((data) => Object.assign(metadata, data));
                          setFields({ ...fields, metadata });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ marginY: 1 }}>
                      <Autocomplete
                        freeSolo
                        multiple={true}
                        options={[]}
                        value={fields.keywords}
                        renderInput={(params) => (<TextField {...params} label="Palabras claves" />)}
                        onChange={(__, keywords) => setFields({ ...fields, keywords })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <SelectUser
                noedit={true}
                userType="users"
                placeholder="Permisos de acceso: visores"
                multiple
                value={viewers}
                onChange={(__, ows) => {
                  setViewers(ows)
                  setFields({ ...fields, viewers: ows.map((o) => o.userId) })
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <SelectUser
                noedit={true}
                userType="users"
                placeholder="Permisos de acceso: editores"
                multiple
                value={editors}
                onChange={(__, ows) => {
                  setEditors(ows)
                  setFields({ ...fields, editors: ows.map((o) => o.userId) })
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={!fields.expedientId || !fields.description || loading}
            >
              {loading ? (
                <CircularProgress size={26} />
              ) : (
                <>
                  {expedient ? "Modificar" : "Crear"} expediente
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => onClose()}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ExpedientFormModal;
