import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UploadButton from "./components/generic/UploadButton";
import MoneyInput from "./components/generic/MoneyInput";

export const getControl = (control) => {
  if (control.onValidation) {
    if (!control.onValidation(control.submission)) return null;
  }

  switch (control.type) {
    case TextField:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <TextField
              placeholder={control.placeholder}
              required={control.required}
              label={control.label}
              value={control.value}
              multiline={control.multiline}
              rows={control.rows}
              onChange={(evt) => control.handleChange(control.key, evt.target.value, control.idx)}
            />
          </FormControl>
        </Grid>
      );
  
    case MoneyInput:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <MoneyInput
              label={control.label}
              onChange={(evt) => control.handleChange(control.key, evt.target.value, control.idx)}
            />
          </FormControl>
        </Grid>
      );
    
    case DatePicker:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <DatePicker
              label={control.label}
              onChange={(value) => control.handleChange(control.key, value, control.idx)}
            />
          </FormControl>
        </Grid>
      );
      
    case Select:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <InputLabel id={`select-${control.key}`}>{control.label}</InputLabel>
            <Select
              labelId={`select-${control.key}`}
              label={control.label}
              value={control.value}
              required={control.required}
              onChange={(evt) => control.handleChange(control.key, evt.target.value, control.idx)}
            >
              {control.options.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    
    case UploadButton:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <UploadButton
              label={control.label}
              onChange={(files) => control.handleChange(control.key, files, control.idx)}
              maxWidth={control.maxWidth}
            />
          </FormControl>
        </Grid>
      )
    
    case Autocomplete:
      return (
        <Grid key={control.key} item xs={control.xs}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              freeSolo={control.freeSolo}
              options={control.options}
              getOptionLabel={control.getOptionLabel ?? ((option) => option)}
              onChange={(__, newValue) => {
                control.handleChange(control.key, newValue, control.idx);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={control.label}
                  placeholder={control.placeholder}
                />
              )}
            />
          </FormControl>
        </Grid>
      );
      
    default:
      return null;
  }
};
